<template>
  <b-link
    v-if="this.showGoToTop"
    title="Voltar ao Topo"
    v-on:click="scrollToTop"
    class="float"
  >
    <i class="fa fa-arrow-up my-float"></i>
  </b-link>
</template>

<style scoped>
.float {
  position: fixed;
  width: 48px;
  height: 48px;
  bottom: 32px;
  right: 32px;
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.float:hover {
  background-color: rgba(0, 0, 0);
  color: rgba(255, 255, 255) !important;
}

.my-float {
  margin-top: 9px;
  font-size: xx-large;
}
</style>

<script>
export default {
  name: "GoToTop",
  props: {
    id: {
      type: String,
      default: "ProductList"
    }
  },
  data() {
    return {
      showGoToTop: false
    };
  },
  created() {
    window.addEventListener("scroll", () => {
      this.showGoToTop = this.nearBottom();
    });
  },
  methods: {
    nearBottom() {
      return window.scrollY > 240;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

import { httpNoLoading } from './configNoLoading'
import { http } from './config'

export default {
    getByCodigo: (codigo) => {
        return http.get('produto/codigo/' + codigo)
    },
    getProdutos: (data) => {
        return httpNoLoading.get('produto', data)
    },
    getListaProdutos: (data) => {
        return httpNoLoading.get('produto/lista-produtos', data)
    },
    getSearchSuggestions: (data) => {
        return httpNoLoading.get('produto/search-suggestions/' + data)
    },
    getValoresCliente: (produto_codigo, cliente_codigo) => {
        return httpNoLoading.get('produto/' + produto_codigo + '/valores-cliente/' + cliente_codigo)
    },
    getValoresMargem: (produto_codigo, cliente_codigo, percentual_margem, valor_margem, quantidade, filial, idOrcamento = null) => {
        percentual_margem = parseFloat(new Intl.NumberFormat("en-US", { maximumFractionDigits: "2" }).format(percentual_margem));
        return httpNoLoading.get('produto/' + 'valores-margem/' + produto_codigo + "/" + cliente_codigo + "/" + percentual_margem + "/" + valor_margem + "/" + quantidade + "/" + filial + (idOrcamento ? ("/" + idOrcamento) : ""))
    },
    getEstoque: (data) => {
        return http.get('estoque/estoque_produtos?produtos=' + data);
    },
    favoritar: (produtoCodigo) => {
        return http.post('produto/favoritar/' + produtoCodigo);
    },
    desfavoritar: (id) => {
        return http.post('produto/desfavoritar/' + id);
    },
}
<template>
  <b-card no-body class="w-100">
    <div class="produto-favorito-div">
      <a v-show="this.favorito == 0" @click="favoritarProduto()">
        <img src="../../../assets/img/produto-favorito.svg" />
      </a>
      <a v-show="this.favorito != 0" @click="desfavoritarProduto()">
        <img src="../../../assets/img/produto-favorito-check.svg" />
      </a>
    </div>
    <a
      :href="this.urlProdutoLoja + '/produto/' + this.codigo"
      rel="noopener noreferrer"
      :title="this.titulo"
      target="_blank"
      class="product-item-link"
    >
      <b-card-img
        class="product-item-img text-center"
        :src="this.url_foto"
        top
      ></b-card-img>
    </a>

    <b-card-body class="product-item-card-body text-center">
      <a
        :href="this.urlProdutoLoja + '/produto/' + this.codigo"
        rel="noopener noreferrer"
        :title="this.titulo"
        target="_blank"
        class="product-item-link"
      >
        <p class="product-item-title">{{ this.titulo }}</p>
        <p class="product-item-code">Cód.: {{ this.codigo }}</p>
        <p class="product-item-multiple">Múltiplo de.: {{ this.multiplo }}</p>
      </a>
    </b-card-body>

    <template v-slot:footer>
      <div v-if="!temCliente">
        <b-row class="mt-1">
          <b-col class="text-center">
            <slot :name="'seeButton'">
              <b-button
                size="sm"
                variant="primary"
                v-on:click="seeButton"
                :disabled="disabled"
                >Ver Preços</b-button
              >
            </slot>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <div v-if="loadingPrices" class="mt-2 mb-2 text-center">
          <b-spinner
            variant="secondary"
            label="Buscando valores..."
            small
          ></b-spinner>
        </div>
        <div v-if="temFilialMga">
          <b-row
            v-if="
              (!loadingPrices && canAdd(1) && exibePrecoMga) ||
              (valida_estoque == 'S' && exibePrecoMga)
            "
            class="margin-bottom2"
          >
            <b-button
              :id="'buttonMga' + codigo"
              class="icones-adiconar fundo-dicomp-mga"
              @click="onAddMga()"
            >
              <i class="fa fa-shopping-cart fa-lg"></i>
              <span
                :id="'confirmMga' + codigo"
                class="circle-confirm display-none"
                ><i class="fa fa-check-circle-o fa-lg"></i
              ></span>
            </b-button>

            <transition
              name="transition"
              v-on:after-leave="afterLeave(1, codigo)"
              v-on:before-enter="beforeEnter(1, codigo)"
              v-on:before-leave="beforeLeave(1, codigo)"
            >
              <b-button
                class="icones-adiconar fundo-dicomp-mga carrinho-transicao carrinho-expandido"
                v-if="showMga"
              >
                <i
                  class="fa fa-arrow-left tamanho-saida"
                  :id="'fechandoMga' + codigo + cd_filial"
                  v-on:click="onAddMga('fechando')"
                ></i>
                <input
                  type="number"
                  :id="'inputMga' + codigo"
                  class="input-expandido border-input margin-right05em"
                  :min="menorValor()"
                  v-model="quantidadeMga"
                  style="margin-left: 0.5em"
                />
                <i
                  class="fa fa-shopping-cart fa-lg tamanho-carrinho"
                  v-on:click="onAddMga()"
                ></i>
              </b-button>
            </transition>

            <div style="width: 50%">
              <div>
                <span class="text-center">
                  <strong v-if="pc_ipi_st.maringa > 0"
                    ><span class="product-item-tax ml-1"
                      >{{ pc_ipi_st.maringa }}% IPI + ST</span
                    ></strong
                  >
                  <strong v-else-if="pc_ipi.maringa > 0"
                    ><span class="product-item-tax ml-1"
                      >{{ pc_ipi.maringa }}% IPI</span
                    ></strong
                  >
                </span>
              </div>
              <div class="margin-top-2">
                <span class="text-center">
                  <strong class="product-item-price mb-1 ml-1"
                    ><span class="cor-azul-money">R$</span>
                    <span class="estilo-valor-preco">{{
                      valor.maringa | money
                    }}</span></strong
                  >
                </span>
              </div>
            </div>
            <div class="texto-filial-mga width-mga" :id="'filialMga' + codigo">
              <span class="color-maringa">MGA</span>
            </div>
          </b-row>
          <b-row
            v-else-if="
              !loadingPrices && !canAdd(1) && exibePrecoMga && canAdd(2)
            "
            class="consulte-nos"
          >
            <b-col class="text-center">
              <slot :name="'outOfStock'">
                <b-button size="sm" variant="danger"> Consulte-nos </b-button>
                <span v-if="!canAdd(1) && canAdd(2)">
                  <span
                    class="texto-filial-mga color-maringa position-consulte-nos-filial-mga"
                    >MGA</span
                  >
                </span>
              </slot>
            </b-col>
          </b-row>
          <b-row
            v-if="
              (canAdd(2) && canAdd(1)) ||
              (exibePrecoItj && exibePrecoMga && (canAdd(1) || canAdd(2)))
            "
            class="border-bottom-1px margin-top2"
          ></b-row>
        </div>

        <div v-if="temFilialItj">
          <b-row
            v-if="
              (canAdd(2) && !loadingPrices && exibePrecoItj) ||
              (valida_estoque == 'S' && exibePrecoItj)
            "
            class="margin-top2"
          >
            <b-button
              :id="'buttonItj' + codigo"
              class="icones-adiconar fundo-dicomp-itj"
              @click="onAddItj()"
            >
              <i class="fa fa-shopping-cart fa-lg"></i>
              <span
                :id="'confirmItj' + codigo"
                class="circle-confirm display-none"
                ><i class="fa fa-check-circle-o fa-lg"></i
              ></span>
            </b-button>

            <transition
              name="transition"
              v-on:after-leave="afterLeave(2, codigo)"
              v-on:before-enter="beforeEnter(2, codigo)"
              v-on:before-leave="beforeLeave(2, codigo)"
            >
              <b-button
                class="icones-adiconar fundo-dicomp-itj carrinho-transicao carrinho-expandido"
                v-if="showItj"
              >
                <i
                  class="fa fa-arrow-left tamanho-saida"
                  :id="'fechandoItj' + codigo + cd_filial"
                  v-on:click="onAddItj('fechando')"
                ></i>
                <input
                  type="number"
                  :id="'inputItj' + codigo"
                  class="input-expandido border-input margin-right05em"
                  :min="menorValor()"
                  v-model="quantidadeItj"
                  style="margin-left: 0.5em"
                />
                <i
                  class="fa fa-shopping-cart fa-lg tamanho-carrinho"
                  v-on:click="onAddItj()"
                ></i>
              </b-button>
            </transition>

            <div style="width: 50%">
              <div>
                <span class="text-center">
                  <strong v-if="pc_ipi_st.itj > 0"
                    ><span class="product-item-tax ml-1"
                      >{{ pc_ipi_st.itj }}% IPI + ST</span
                    ></strong
                  >
                  <strong v-else-if="pc_ipi.itj > 0"
                    ><span class="product-item-tax ml-1"
                      >{{ pc_ipi.itj }}% IPI</span
                    ></strong
                  >
                </span>
              </div>
              <div class="margin-top-2">
                <span class="text-center">
                  <strong class="product-item-price mb-1 ml-1"
                    ><span class="cor-laranja-money">R$</span
                    ><span class="estilo-valor-preco-itj">
                      {{ valor.itj | money }}</span
                    ></strong
                  >
                </span>
              </div>
            </div>
            <div class="texto-filial-itj width-itj" :id="'filialItj' + codigo">
              <span class="color-itj">ITJ</span>
            </div>
          </b-row>
          <b-row
            v-else-if="
              !loadingPrices && !canAdd(2) && exibePrecoItj && canAdd(1)
            "
            class="consulte-nos"
          >
            <b-col class="text-center">
              <slot :name="'outOfStock'">
                <b-button size="sm" variant="danger"> Consulte-nos </b-button>
                <span v-if="!canAdd(2) && canAdd(1)">
                  <span
                    class="color-itj texto-filial-itj position-consulte-nos-filial-itj"
                    >ITJ</span
                  >
                </span>
              </slot>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="
            !loadingPrices && !canAdd(1) && !canAdd(2) && valida_estoque == 'N'
          "
        >
          <b-row class="consulte-nos">
            <b-col class="text-center">
              <slot :name="'outOfStock'">
                <b-button size="sm" variant="danger"> Consulte-nos </b-button>
              </slot>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </b-card>
</template>
<style scoped>
.product-item-link {
  text-decoration: none;
  color: #000;
}
.product-item-tax {
  font-size: x-small;
}
.product-item-price {
  font-size: small;
}
.product-item-img {
  max-width: 100%;
  min-height: 8em;
  background-image: url("../../../../public/img/logo.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-color: #f9f9f9;
  background-position-y: center;
  background-position-x: center;
}
.product-item-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: small;
  margin-bottom: 0.5em;
}
.product-item-code {
  font-size: x-small;
  font-weight: bold;
  margin-bottom: 0;
  color: #474747;
}
.product-item-multiple {
  font-size: x-small;
  font-weight: bold;
  margin-bottom: 0;
  color: #474747;
}
.product-item-card-body {
  padding: 0.4em;
}

.card-footer {
  padding: 0.45rem 1.05rem;
}

.icones-adiconar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 40px;
  border-radius: 0px 6px 6px 0px;
  color: #fff;
}

.tamanho-carrinho {
  height: 26px;
  margin-top: 0.7em !important;
}

.tamanho-saida {
  height: 26px;
  margin-top: 0.8em !important;
}

.fundo-dicomp-mga {
  background-color: #0075eb;
}

.fundo-dicomp-itj {
  background-color: #ff6d13;
}

.border-bottom-1px {
  border-bottom: 1px solid #c8ced3;
}

.margin-top2 {
  margin-top: 2%;
}

.margin-bottom2 {
  margin-bottom: 2%;
}

.margin-top-2 {
  margin-top: -2%;
}

.cor-azul-money {
  color: #4187de;
}

.cor-laranja-money {
  color: #ff6d13;
}

.estilo-valor-preco {
  font-size: 18px;
  color: #0075eb;
}

.estilo-valor-preco-itj {
  font-size: 18px;
  color: #ff6d13;
}

.texto-filial-itj {
  font-size: 22px;
  margin-top: -2%;
  opacity: 0.7;
}

.texto-filial-mga {
  font-size: 22px;
  margin-top: -2%;
  opacity: 0.7;
}

.width-mga {
  margin-left: 8%;
}

.width-itj {
  margin-left: 18%;
}

.color-maringa {
  color: #96c5f4;
  font-weight: 500;
}

.color-itj {
  color: #fa9558;
  font-weight: 500;
}

.consulte-nos {
  height: 3.7em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-confirm {
  color: #31de31;
  position: absolute;
  margin-left: 1.4em;
  margin-top: -0.5em;
}

.position-consulte-nos-filial-itj {
  position: absolute;
  margin-top: -0.7em;
  margin-left: 1.7em;
}

.position-consulte-nos-filial-mga {
  position: absolute;
  margin-left: 0.6em;
  margin-top: -1em;
}

.transition-enter-active {
  animation: transition-frame 0.7s ease;
}

@keyframes transition-frame {
  from {
    transform-origin: top left;
    transform: scaleX(1);
    width: 3em;
  }
  to {
    transform-origin: top left;
    transform: scaleX(1);
    width: 8em;
  }
}

.transition-leave-active {
  animation: transition-frame 2s ease reverse;
  pointer-events: none;
}

.carrinho-transicao {
  height: 45px;
  width: 8em;
}

.carrinho-expandido {
  border: 1px solid #fff;
  padding: 3%;
  border-radius: 6px;
}

.border-input {
  border-radius: 6px;
  border: none;
  width: 4em;
}

@keyframes transition-input-frame {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  from {
    transform-origin: top left;
    transform: scaleX(0);
    width: 0em;
  }
  to {
    transform-origin: top left;
    transform: scaleX(1);
    width: 4em;
  }
}

.input-expandido {
  animation: transition-input-frame 1s;
}

.width5em {
  width: 5em;
}

.margin-right05em {
  margin-right: 0.5em;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

@media screen and (max-width: 1910px) {
  .carrinho-transicao {
    width: 7em;
  }

  .border-input {
    width: 4em;
  }

  .estilo-valor-preco {
    font-size: 18px;
  }

  .width-mga {
    margin-left: 6%;
  }

  .width-itj {
    margin-left: 16%;
  }

  .estilo-valor-preco-itj {
    font-size: 18px;
  }

  @keyframes transition-input-frame {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    from {
      transform-origin: top left;
      transform: scaleX(0);
      width: 0em;
    }
    to {
      transform-origin: top left;
      transform: scaleX(1);
      width: 4em;
    }
  }

  @keyframes transition-frame {
    from {
      transform-origin: top left;
      transform: scaleX(1);
      width: 3em;
    }
    to {
      transform-origin: top left;
      transform: scaleX(1);
      width: 7em;
    }
  }
}

@media screen and (max-width: 1750px) {
  .carrinho-transicao {
    width: 6em;
  }

  .border-input {
    width: 3.2em;
  }

  .width-mga {
    margin-left: 4%;
  }

  .width-itj {
    margin-left: 14%;
  }

  .estilo-valor-preco {
    font-size: 16px;
  }

  .position-consulte-nos-filial-itj {
    margin-left: 1.1em;
  }

  .position-consulte-nos-filial-mga {
    margin-left: 0.1em;
  }

  .estilo-valor-preco-itj {
    font-size: 16px;
  }

  @keyframes transition-input-frame {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    from {
      transform-origin: top left;
      transform: scaleX(0);
      width: 0em;
    }
    to {
      transform-origin: top left;
      transform: scaleX(1);
      width: 3.2em;
    }
  }

  @keyframes transition-frame {
    from {
      transform-origin: top left;
      transform: scaleX(1);
      width: 3em;
    }
    to {
      transform-origin: top left;
      transform: scaleX(1);
      width: 6em;
    }
  }
}

@media screen and (max-width: 1820px) {
  .input-expandido {
    width: 3em;
  }

  .position-consulte-nos-filial-mga {
    margin-left: 0.3em;
  }
}
@media screen and (max-width: 1700px) {
  .texto-filial-mga {
    font-size: 18px;
  }

  .texto-filial-itj {
    font-size: 18px;
  }

  .consulte-nos {
    height: 3.5em;
  }
}

@media screen and (max-width: 1630px) {
  .carrinho-transicao {
    width: 5.5em;
  }

  .input-expandido {
    width: 3em;
  }

  .position-consulte-nos-filial-mga {
    margin-left: 0.1em;
  }
}

@media screen and (max-width: 1540px) {
  .estilo-valor-preco {
    font-size: 13px;
  }

  .estilo-valor-preco-itj {
    font-size: 13px;
  }

  .texto-filial-mga {
    font-size: 16px;
  }

  .texto-filial-itj {
    font-size: 16px;
  }
}

@media screen and (max-width: 1500px) {
  .width11em {
    width: 6em;
  }

  .consulte-nos {
    height: 3.4em;
  }
}

.produto-favorito-div {
  position: absolute;
  width: 25px;
  right: 0;
}
</style>
<script>
import Produto from "@/services/produto";
import { mapState } from "vuex";
import GrupoPermissao from "../../../services/grupoPermissao";

export default {
  name: "ProductItem",
  props: {
    id: {
      type: String,
      default: "ProductItem",
    },
    codigo: {
      type: Number,
      default: 0,
    },
    multiplo: {
      type: Number,
      default: 1,
    },
    valor_unitario: {
      type: Number,
      default: 0,
    },
    cliente_codigo: {
      type: Number,
      default: 0,
    },
    titulo: {
      type: String,
      default: "",
    },
    url_foto: {
      type: String,
      default: "",
    },
    tipo_origem_produto: {
      type: String,
      default: "",
    },
    estoques: {
      type: Array,
      default: [],
    },
    cd_filial: {
      type: Number,
      default: 0,
    },
    valida_estoque: {
      type: String,
      default: "N",
    },
    favorito: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loadingPrices: false,
      valor: [],
      valor_st: [],
      valor_ipi: [],
      pc_st: [],
      pc_ipi_st: [],
      pc_ipi: [],
      urlProdutoLoja: process.env.VUE_APP_LOJA,
      exibePrecoItj: false,
      exibePrecoMga: false,
      quantidadeMga: this.multiplo,
      quantidadeItj: this.multiplo,
      showItj: false,
      showMga: false,
      codigoAnterior: "",
      filialAnterior: "",
      origemFechando: false,
      disabled: false,
    };
  },
  mounted() {
    this.validaPermissao();
    this.getPrices();
  },
  watch: {
    cliente_codigo() {
      this.getPrices();
    },
  },
  computed: {
    ...mapState("carrinho", {
      clienteCodigo: "cliente_codigo",
    }),
    ...mapState("carrinhoitj", {
      clienteCodigo: "cliente_codigo",
    }),
    temCliente() {
      if (
        this.$store.state.carrinho.cliente_codigo == null &&
        this.$store.state.carrinhoitj.cliente_codigo == null
      ) {
        return false;
      }

      return true;
    },
    temFilialMga() {
      return this.cd_filial == 1 || this.cd_filial == 0;
    },
    temFilialItj() {
      return this.cd_filial == 2 || this.cd_filial == 0;
    },
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/produtos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    getData(quantidade) {
      let data = {
        codigo: this.codigo,
        multiplo: this.multiplo,
        titulo: this.titulo,
        tipo_origem_produto: this.tipo_origem_produto,
        estoque: this.estoques,
        quantidade: quantidade,
      };
      return data;
    },
    onAddMga(origem) {
      this.showMga = !this.showMga;
      if (!this.showMga && this.quantidadeMga > 0) {
        let idFecha = "fechandoMga" + this.codigo + this.cd_filial;
        if (origem != "fechando") {
          this.origemFechando = false;
          this.$emit("onAddMga", this.getData(this.quantidadeMga));
          document.getElementById(idFecha).style.display = "none";
        } else {
          this.origemFechando = true;
          document.getElementById(idFecha).style.display = "none";
        }
      }
    },
    onAddItj(origem) {
      this.showItj = !this.showItj;
      if (!this.showItj && this.quantidadeItj > 0) {
        let idFecha = "fechandoItj" + this.codigo + this.cd_filial;
        if (origem != "fechando") {
          this.$emit("onAddItj", this.getData(this.quantidadeItj));
          document.getElementById(idFecha).style.display = "none";
        } else {
          this.origemFechando = true;
          document.getElementById(idFecha).style.display = "none";
        }
      }
    },
    seeButton() {
      this.$emit("onAdd", false);
    },
    getPrices() {
      let cliente_codigo =
        this.$store.state.carrinho.cliente_codigo != null
          ? this.$store.state.carrinho.cliente_codigo
          : this.$store.state.carrinhoitj.cliente_codigo;

      if (cliente_codigo != null) {
        this.getValoresCliente(cliente_codigo);
      }
    },
    getValoresCliente(cliente_codigo) {
      this.loadingPrices = true;
      Produto.getValoresCliente(this.codigo, cliente_codigo)
        .then((response) => {
          if (response.data.data) {
            let values = response.data.data;
            if (Array.isArray(values)) {
              for (let i = 0; i < values.length; i++) {
                if (values[i].cdFilial == 1) {
                  this.preencheMaringa(values[i]);
                }

                if (values[i].cdFilial == 2) {
                  this.preencheItj(values[i]);
                }
              }

              if (
                values[0].valor_unitario == 0 &&
                values[1].valor_unitario == 0
              ) {
                this.$emit("onHideProduct", this.codigo);
              }
            } else {
              if (values.cdFilial == 1) {
                this.preencheMaringa(values);
              }

              if (values.cdFilial == 2) {
                this.preencheItj(values);
              }

              if (values.valor_unitario == 0) {
                this.$emit("onHideProduct", this.codigo);
              }
            }
          } else {
            this.$emit("onHideProduct", this.codigo);
          }
        })
        .catch((e) => {
          this.$emit("onHideProduct", this.codigo);
        })
        .finally(() => {
          this.loadingPrices = false;
        });
    },
    menorValor() {
      return this.multiplo;
    },
    beforeEnter: function (filial, codigo) {
      if (filial == 1) {
        this.beforeAdicionaERemoveClassesParaTransicao("Mga", codigo);
      } else {
        this.beforeAdicionaERemoveClassesParaTransicao("Itj", codigo);
      }
    },
    beforeLeave(filial, codigo) {
      if (filial == 1) {
        let id = "inputMga" + codigo;
        let obj = document.getElementById(id);
        setTimeout(function () {
          obj.remove();
        }, 600);
      } else {
        let id = "inputItj" + codigo;
        let obj = document.getElementById(id);
        setTimeout(function () {
          obj.remove();
        }, 600);
      }
    },
    afterLeave: function (filial, codigo) {
      if (filial == 1) {
        this.afeterAdicionaClassesParaTransicao("Mga", codigo);
      } else {
        this.afeterAdicionaClassesParaTransicao("Itj", codigo);
      }
    },
    beforeAdicionaERemoveClassesParaTransicao(origem, codigo) {
      let id = "button" + origem + codigo;
      let filial = "filial" + origem + codigo;
      document.getElementById(id).classList.remove("display-block");
      document.getElementById(filial).classList.remove("display-block");
      document.getElementById(id).classList.add("display-none");
      document.getElementById(filial).classList.add("display-none");
    },
    afeterAdicionaClassesParaTransicao(origem, codigo) {
      let id = "button" + origem + codigo;
      let filial = "filial" + origem + codigo;
      let idConfirm = "confirm" + origem + codigo;

      document.getElementById(id).classList.add("display-block");
      document.getElementById(filial).classList.add("display-block");
      if (!this.origemFechando) {
        document.getElementById(idConfirm).style.display = "block";
        setTimeout(function () {
          document.getElementById(idConfirm).style.display = "none";
        }, 2500);
      }
    },
    preencheMaringa(values) {
      this.exibePrecoMga = true;
      this.valor.maringa = values.valor_unitario;
      this.valor_st.maringa = values.valor_st;
      this.valor_ipi.maringa = values.valor_ipi;
      this.pc_st.maringa = values.pc_icms;
      this.pc_ipi_st.maringa = 0;
      if (values.pc_st > 0) {
        this.pc_ipi_st.maringa = values.pcIpi + values.pc_st;
      } else {
        this.pc_ipi.maringa = values.pcIpi;
      }
    },
    preencheItj(values) {
      this.exibePrecoItj = true;
      this.valor.itj = values.valor_unitario;
      this.valor_st.itj = values.valor_st;
      this.valor_ipi.itj = values.valor_ipi;
      this.pc_st.itj = values.pc_icms;
      this.pc_ipi_st.itj = 0;
      if (values.pc_st > 0) {
        this.pc_ipi_st.itj = values.pcIpi + values.pc_st;
      } else {
        this.pc_ipi.itj = values.pcIpi;
      }
    },
    canAdd(filial) {
      let estoquesAux = [];
      for (let i = 0; i < this.estoques.length; i++) {
        if (this.estoques[i].codigo_filial == filial) {
          estoquesAux.push(this.estoques[i]);
        }
      }
      let estoque = estoquesAux.filter(
        (el) => el.qt_estoque > 0 && el.codigo_filial == filial
      );
      if (estoque.length == 0) {
        if (this.tipo_origem_produto != "NACIONAL") {
          return false;
        }
        return false;
      }

      return true;
    },
    favoritarProduto() {
      Produto.favoritar(this.codigo).then((response) => {
        this.favorito = 0;
        if (response.data.data) {
          this.favorito = response.data.data;
          this.$helper.showMsg("Favoritado", "success");
          this.$emit("onFavoritado", this.codigo, this.favorito);
        }
      });
    },
    desfavoritarProduto() {
      Produto.desfavoritar(this.favorito).then((response) => {
        this.favorito = 0;
        this.$emit("onFavoritado", this.codigo, this.favorito);
      });
    },
  },
};
</script>
